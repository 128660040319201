@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Inter;
  src: url(assets/fonts/Inter-Light.ttf) format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url(assets/fonts/Inter-Regular.ttf) format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url(assets/fonts/Inter-Medium.ttf) format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url(assets/fonts/Inter-SemiBold.ttf) format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url(assets/fonts/Inter-Bold.ttf) format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: HeleveticaNeue;
  src: url(assets/fonts/HelveticaNeue.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: HeleveticaNeueBlack;
  src: url(assets/fonts/HelveticaNeue-Black.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}

@layer base {
  html, body {
    font-family: 'Inter', serif;
    margin: 0;
    padding: 0;
  }
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
//hide arrows if inputfield is an number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* override reduced-motion setting of fontawesome... */
.fa-spin {
  -webkit-animation-name: fa-spin;
  animation-name: fa-spin;

  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);

  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);

  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);

  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);

  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}
